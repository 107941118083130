import styled from 'styled-components';

const DialogHeader = styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    h3 {
        margin: 0;
    }
`

export default DialogHeader