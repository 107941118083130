import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MenuItem from './MenuItem'
import MenuSectionItem from './MenuSectionItem'

import StoreContext from '../../context/StoreContext'
import getCategoryLink from '../../utils/getCategoryLink'

const getStoreCategories = require('../../utils/getStoreCategories')

const MenuCategories = () => {
    const {
        allProductsCsv: { distinct },
    } = useStaticQuery(graphql`
        query ProductsCategory {
            allProductsCsv {
                distinct(field: Categoria)
            }
        }
    `)
    return (
        <StoreContext.Consumer>
            {({ store }) => {
                store = store || 'pulpe'
                return (
                    <ul>
                        <li>
                            <MenuSectionItem>Categorías</MenuSectionItem>
                        </li>
                        {getStoreCategories(distinct, store).map((cat, i) => {
                            const link = getCategoryLink(cat, store)

                            return (
                                <li key={i}>
                                    {cat === 'Combos' ? (
                                        <b>
                                            <MenuItem to={link}>{cat}</MenuItem>
                                        </b>
                                    ) : (
                                        <MenuItem to={link}>{cat}</MenuItem>
                                    )}
                                </li>
                            )
                        })}
                    </ul>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default MenuCategories
