const excludedCategoriesByStore = {
    pulpe: ['Comida Preparada', ''],
    emerson: [
        'Cigarrillos',
        'Combos',
        'Carnes, Embutidos y Quesos',
        'Limpieza y Hogar',
        'Otros',
        '',
    ],
    smile: [
        'Cigarrillos',
        'Combos',
        'Carnes, Embutidos y Quesos',
        'Limpieza y Hogar',
        'Otros',
        '',
    ],
}

const getStoreCategories = (categories, storeName)=>
    categories.filter(
        category => !excludedCategoriesByStore[storeName].includes(category)
    )

module.exports = getStoreCategories
