import { useState, useEffect } from 'react'
import getFirebase from '../utils/firebase'

const useOrdersActions = ({ store }) => {
    const [value, setValue] = useState({})
    useEffect(() => {
        const app = import('firebase/app')
        const firestore = import('firebase/firestore')

        Promise.all([app, firestore]).then(([firebase]) => {
            const instance = getFirebase(firebase)
            const storeDb = instance.firestore().collection(`${store}-orders`)

            setValue({
                add: content =>
                    storeDb.add({
                        ...content,
                        createdAt: new Date(),
                        complete: false,
                        cancelled: false,
                        delivered: false,
                    }),
                markAsComplete: id =>
                    storeDb.doc(id).update({
                        complete: true,
                        completedAt: new Date(),
                    }),
                markAsDelivered: id =>
                    storeDb.doc(id).update({
                        delivered: true,
                        deliveredAt: new Date(),
                    }),
                cancel: id =>
                    storeDb.doc(id).update({
                        cancellAt: new Date(),
                        cancelled: true,
                        complete: false,
                        delivered: false,
                    }),
            })
        })
    }, [store])

    return value
}

export default useOrdersActions
