import React from 'react'

const StoreContext = React.createContext({
    store: null,
    order: [],
    addItem: () => {},
    removeItem: () => {},
    clearOrder: () => {},
})

export default StoreContext
