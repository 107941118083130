import React from 'react'
import styled from 'styled-components'
import OrderTotal from './OrderTotal'

const Total = styled.span`
    display: block;
    font-size: 1.3rem;
    font-weight: bold;
    margin: 0;
    padding: 0.25rem 0 0;
    text-align: right;
    border-top: solid 2px ${props => props.theme.black};
`

const CheckoutTotal = ({ order }) => {
    return (
        <Total>
            Total: <OrderTotal order={order} />*
        </Total>
    )
}

export default CheckoutTotal
