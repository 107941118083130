/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components'
import { StaticQuery, graphql } from 'gatsby'

import Header from './Header'
import SEO from './Seo'
import Footer from './Footer'

import theme from '../utils/style-components-theme'

import Whatsapp from './Whatsapp'
import IconWhatsapp from '../assets/ico-whatsapp.svg'

import '../scss/index.scss'

const Layout = ({
    children,
    seotitle,
    seoimage,
    seourl,
    isStore,
    location,
}) => {
    return (
        <StaticQuery
            query={graphql`
                query SiteTitleQuery {
                    site {
                        siteMetadata {
                            title
                        }
                    }
                }
            `}
            render={data => (
                <>
                    <SEO
                        title={seotitle}
                        keywords={[]}
                        lang="es"
                        image={seoimage}
                        url={seourl}
                    />
                    <ThemeProvider theme={theme}>
                        <Header
                            location={location}
                            isStore={isStore}
                            siteTitle={data.site.siteMetadata.title}
                        />
                        {children}
                        <Footer />
                        <Whatsapp
                            title="Hablemos por Whatsapp"
                            href="https://api.whatsapp.com/send?phone=50672155831‬"
                        >
                            <IconWhatsapp />
                        </Whatsapp>
                    </ThemeProvider>
                </>
            )}
        />
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
