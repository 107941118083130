const config = {
    apiKey: process.env.GATSBY_FIREBASE_APIKEY,
    authDomain: process.env.GATSBY_FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.GATSBY_FIREBASE_DATABASEURL,
    projectId: process.env.GATSBY_FIREBASE_PROJECTID,
    storageBucket: process.env.GATSBY_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.GATSBY_FIREBASE_APPID,
    measurementId: process.env.GATSBY_FIREBASE_MEASUREMENTID,
}

let instance
export default function getFirebase(app) {
    if (typeof window !== 'undefined') {
        if (instance) return instance
        instance = app.initializeApp(config)
        return instance
    }

    return () => {}
}
