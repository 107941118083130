/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

import ogImage from '../assets/og-image-home.jpg'

function SEO({ description, lang, meta, keywords, title, image, url }) {
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        description
                        author
                        siteUrl
                        seo {
                            keywords
                        }
                        facebook {
                            app_id
                        }
                        google {
                            siteVerification
                        }
                    }
                }
            }
        `
    )

    const metaDescription = description || site.siteMetadata.description
    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`${site.siteMetadata.title} | %s`}
            meta={[
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: 'og:image',
                    content: `${image || ogImage}`,
                },
                {
                    property: 'og:image:width',
                    content: '200',
                },
                {
                    property: 'og:image:height',
                    content: '200',
                },
                {
                    property: 'og:url',
                    content: `${site.siteMetadata.siteUrl}${url}/`,
                },
                {
                    property: 'fb:app_id',
                    content: site.siteMetadata.facebook.app_id,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },

                {
                    name: 'google-site-verification',
                    content: site.siteMetadata.google.siteVerification,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: `keywords`,
                    content: Array.from(
                        new Set([
                            ...site.siteMetadata.seo.keywords,
                            ...keywords,
                        ])
                    ).join(', '),
                },
            ].concat(meta)}
        />
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    keywords: [],
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
    keywords: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string.isRequired,
}

export default SEO
