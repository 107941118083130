import React from 'react'
import styled from 'styled-components'

const Copy = styled.p`
    text-align: center;
`

const Wrapper = styled.footer`
    margin: 4rem var(--spacing-global);
`


const Footer = () => {
    return (
        <Wrapper>
            <Copy>
                Copyright© {new Date().getFullYear()} Corporate Convenience
                Store SRL.
            </Copy>
        </Wrapper>
    )
}

export default Footer
