import { css } from 'styled-components'

// Styled component media query utility
export const mediaQuery = {
    isBig: (...args) => css`
        @media (min-width: ${BREAKPOINT.big}px) {
            ${css(...args)};
        }
    `,

    isHuge: (...args) => css`
        @media (min-width: ${BREAKPOINT.huge}px) {
            ${css(...args)};
        }
    `,

    extraHuge: (...args) => css`
        @media (min-width: ${BREAKPOINT.extraHuge}px) {
            ${css(...args)};
        }
    `,
}

export const BREAKPOINT = {
    big: 768,
    huge: 992,
    extraHuge: 1200
}

export default {
    isBigMatch: () => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(`(min-width: ${BREAKPOINT.big}px)`)
        } else {
            return {}
        }
    }
}
