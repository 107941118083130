import React from 'react'

function formatMoney(number) {
    return parseFloat(number, 10).toLocaleString('es-CR', {
        currency: 'CRC',
        minimumFractionDigits: 0,
        style: 'currency',
    })
}

const Price = props => {
    const price = isNaN(props.children) ? '0' : props.children
    return (
        <span {...props}>
            {price
                ? price === '0'
                    ? 'Consultar'
                    : formatMoney(price)
                : '¿?'}
        </span>
    )
}

export default Price
