import React, { useState } from 'react'
import styled from 'styled-components'

import IcnCart from '../assets/icn-cart.svg'
import Checkout from './Checkout'

import StoreContext from '../context/StoreContext'

import { mediaQuery } from '../utils/media-queries'

import { CLOSE_MESSAGE } from '../utils/isClose'

const Button = styled.button`
    align-items: center;
    background-color: ${props => props.theme.brandColor1};
    border: 0;
    border-radius: 0.2rem;
    color: ${props => props.theme.brandColor1Dark};
    display: flex;
    padding: 0.5rem 0.75rem;
    position: relative;
    text-decoration: none;

    svg {
        ${mediaQuery.isBig`
            width: 32px;
            height: 32px;
        `}
    }

    span {
        background-color: ${props => props.theme.brandColor2};
        color: ${props => props.theme.brandColor2Dark};
        font-size: 0.75rem;
        padding: 0 0.3rem;
        position: absolute;
        right: -25%;
        top: -25%;
        border-radius: 25%;
    }
`

const CheckoutButton = () => {
    const [showCheckout, setShowCheckout] = useState(false)

    return (
        <StoreContext.Consumer>
            {({ order, store, isClose }) => {
                return (
                    <>
                        {showCheckout && (
                            <Checkout
                                store={store}
                                order={order}
                                onClose={() => {
                                    setShowCheckout(false)
                                }}
                            />
                        )}
                        <Button
                            ariaLabel="Pedir"
                            onClick={e => {
                                if (isClose) {
                                    alert(CLOSE_MESSAGE)
                                    return
                                }

                                if (order.length === 0) {
                                    e.preventDefault()
                                    alert(
                                        'Agrega productos para hacer tu pedido'
                                    )
                                    return
                                }
                                setShowCheckout(!showCheckout)
                            }}
                        >
                            <IcnCart />
                            <span>{order.length}</span>
                        </Button>
                    </>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default CheckoutButton
