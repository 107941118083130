import styled from 'styled-components'

const Field = styled.label`
    border-radius: 0.2rem;
    border: solid 2px #cdcdcd;
    cursor: text;
    display: block;
    margin: 1.5rem 0;
    padding: 1rem 1.25rem 0.5rem;
    position: relative;
    transition: border-color 100ms ease-in-out;

    &.focus {
        border-color: ${props => props.theme.brandColor1};
    }

    &.invalid {
        border-color: ${props => props.theme.errorColor};
    }

    span {
        background-color: #fff;
        font-size: 0.75rem;
        left: 1rem;
        padding: 0 0.25rem;
        position: absolute;
        top: -0.5rem;
    }

    &.required span {
        border-left: solid 2px ${props => props.theme.errorColor};
    }

    input[type='text'],
    input[type='email'],
    input[type='tel'], 
    input[type='number'],
    textarea {
        border: 0;
        width: 100%;

        &:invalid {
            box-shadow: none;
        }

        ::placeholder {
            color: ${props => props.theme.gray};
            opacity: 1;
        }
    }

    select {
        min-width: 50%;
    }

    textarea {
        height: 6rem;
    }
`

export default Field;
