import React from 'react'
import styled from 'styled-components'
import Portal from './Portal'
import Div100vh from 'react-div-100vh'

import { mediaQuery } from '../utils/media-queries'
import { Helmet } from 'react-helmet'

const Wrapper = styled.div`
    background-color: #fff;
    border: solid 2px ${props => props.theme.gray};
    bottom: 1rem;
    padding: 2rem 1rem 1rem;
    position: fixed;
    right: 5%;
    top: 1rem;
    width: 90%;
    z-index: 2;

    ${mediaQuery.isHuge`
        max-width: 680px;
    `}
`
const Overlay = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    right: 0;
    top: 0;
    left: 0;
    z-index: 2;
    position: fixed;
`

const Dialog = ({ children, onDialogClick }) => {
    return (
        <Portal>
            <Helmet
                htmlAttributes={{
                    style: 'overflow:hidden',
                }}
            />
            <Overlay onClick={onDialogClick} />
            <Div100vh>
                <Wrapper>{children}</Wrapper>
            </Div100vh>
        </Portal>
    )
}

export default Dialog
