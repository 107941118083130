const submitForm = (id, value) => {
    return fetch(`https://submit-form.com/${id}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        body: JSON.stringify(value),
    })
}

export default submitForm
