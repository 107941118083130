import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    text-align: center;
`

const CheckoutEmpty = ({ onClick }) => {
    return (
        <Wrapper>
            <p>Necesitas agregar productos.</p>
            <button
                onClick={() => {
                    onClick()
                }}
            >
                Agregar productos
            </button>
        </Wrapper>
    )
}

export default CheckoutEmpty
