import React from 'react'

const Logo = () => {
    return (
        <svg
            width={"80px"}
            height={"40px"}
            viewBox="0 0 67 40"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <title>La Pulpe</title>
            <desc>Mini Market</desc>
            <defs></defs>
            <g
                id="LaPulpeLogo"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="Form-/-Step-2"
                    transform="translate(-20.000000, -20.000000)"
                >
                    <g
                        id="Logo-Color-Copy"
                        transform="translate(20.000000, 20.000000)"
                    >
                        <path
                            d="M3.16977025,19.9989954 C24.7585946,19.9989954 35.5530068,19.9989954 35.5530068,19.9989954 C35.5530068,19.9989954 38.6228514,2.77789532 38.6228514,2.77789532 C38.896383,1.2437424 37.8607913,0 36.3098043,0 C36.3098043,0 9.70688472,0 9.70688472,0 C8.15589766,0 6.6768019,1.2437424 6.40342415,2.77789532 C6.40342415,2.77789532 5.32553952,8.51826202 3.16977025,19.9989954 Z"
                            id="Fill-1"
                            fill="#9CBF3B"
                        ></path>
                        <path
                            d="M0.0442176896,37.2222126 C-0.231869312,38.7563688 0.813607885,40 2.37913644,40 C2.37913644,40 60.6885123,40 60.6885123,40 C62.2541651,40 63.7471225,38.7563688 64.0232095,37.2222126 C64.0232095,37.2222126 66.6224411,22.7777905 66.6224411,22.7777905 C66.8985592,21.2436282 65.8530509,20 64.2873981,20 C64.2873981,20 3.14309002,20.0008524 3.14309002,20.0008524 C3.14309002,20.0008524 0.0442176896,37.2222126 0.0442176896,37.2222126 Z"
                            id="Fill-2"
                            fill="#F26821"
                        ></path>
                        <path
                            d="M16.8358804,17.9800214 C16.8358804,17.9800214 15.3128177,18 15.3128177,18 C14.1983231,18 13.3192163,17.8514716 12.6754973,17.5544461 C12.0317782,17.2574206 11.5454286,16.8618757 11.2165074,16.3677176 C10.8874678,15.8736844 10.7060564,15.2646183 10.6720066,14.5401133 C10.6379568,13.8157331 10.7667539,12.5823047 11.058161,10.839703 C11.058161,10.839703 12.2020865,4 12.2020865,4 C12.2020865,4 15.0516113,4 15.0516113,4 C15.0516113,4 13.8942731,10.9198983 13.8942731,10.9198983 C13.7011367,12.0750357 13.6089061,12.8727123 13.6174926,13.3133027 C13.6261679,13.7538932 13.8061581,14.1444435 14.157641,14.4850161 C14.5091534,14.8254326 15.0902208,14.9957189 15.9007839,14.9957189 C15.9007839,14.9957189 17.3333333,15.0057706 17.3333333,15.0057706 C17.3333333,15.0057706 16.8358804,17.9800214 16.8358804,17.9800214 Z"
                            id="Fill-3"
                            fill="#73933C"
                        ></path>
                        <path
                            d="M27.8335923,12.5360817 C27.8335923,12.5360817 27.0665034,8.40315864 27.0665034,8.40315864 C27.0665034,8.40315864 24.8023418,12.5360817 24.8023418,12.5360817 C24.8023418,12.5360817 27.8335923,12.5360817 27.8335923,12.5360817 Z M32,18 C32,18 28.8781529,18 28.8781529,18 C28.8781529,18 28.3054418,14.9278054 28.3054418,14.9278054 C28.3054418,14.9278054 23.481628,14.9278054 23.481628,14.9278054 C23.481628,14.9278054 21.8086045,18 21.8086045,18 C21.8086045,18 18.6666667,18 18.6666667,18 C18.6666667,18 25.534115,5.46104398 25.534115,5.46104398 C25.8870839,4.94727507 26.2501609,4.57531512 26.623346,4.3452577 C26.9964998,4.1150755 27.397969,4 27.8275965,4 C28.2640046,4 28.6267991,4.12168868 28.9158858,4.365222 C29.2050038,4.6088801 29.4372714,4.9741333 29.6127199,5.46104398 C29.6127199,5.46104398 32,18 32,18 Z"
                            id="Fill-4"
                            fill="#73933C"
                        ></path>
                        <path
                            d="M10.9498212,26.894161 C10.9498212,26.894161 10.3721143,30.2389705 10.3721143,30.2389705 C10.3721143,30.2389705 10.6957537,30.2389705 10.6957537,30.2389705 C11.7095773,30.2389705 12.3971315,30.1773775 12.7585691,30.0536796 C13.1199762,29.9301097 13.4199459,29.7366714 13.6584477,29.4728589 C13.8969494,29.2091745 14.0461544,28.9037475 14.1061239,28.5565781 C14.1810781,28.1226178 14.1067661,27.767048 13.8832185,27.4899998 C13.6597015,27.2130796 13.3449,27.0378498 12.9390892,26.9642729 C12.6462449,26.9176981 12.0354798,26.894161 11.1067939,26.894161 C11.1067939,26.894161 10.9498212,26.894161 10.9498212,26.894161 Z M6,38 C6,38 8.41838151,24 8.41838151,24 C8.41838151,24 12.0089048,24 12.0089048,24 C13.6701602,24 14.740467,24.0701119 15.2199476,24.2103357 C15.955881,24.4172689 16.5246278,24.8729963 16.9262184,25.5772683 C17.3277784,26.2817899 17.4322433,27.1913094 17.2397661,28.3061262 C17.0678393,29.3010567 16.7810806,30.0903307 16.3792759,30.6745883 C15.9775936,31.2588428 15.5156666,31.7177481 14.9936783,32.0515538 C14.4715371,32.3854875 13.9620259,32.6089591 13.4650836,32.7224742 C12.7878658,32.8560989 11.8312594,32.9227864 10.595142,32.9227864 C10.595142,32.9227864 9.8888722,32.9227864 9.8888722,32.9227864 C9.8888722,32.9227864 9.01180715,38 9.01180715,38 C9.01180715,38 6,38 6,38 Z"
                            id="Fill-5"
                            fill="#B44F26"
                        ></path>
                        <path
                            d="M19.6415492,24 C19.6415492,24 22.840705,24 22.840705,24 C22.840705,24 21.5285841,31.4534905 21.5285841,31.4534905 C21.4012414,32.1766622 21.3391963,32.7155329 21.3423262,33.0704774 C21.3687153,33.629146 21.5765141,34.0794357 21.9659373,34.4211006 C22.3552378,34.7628916 22.8025636,34.933787 23.3079454,34.933787 C23.7082618,34.933787 24.1385881,34.7727906 24.5988016,34.4506686 C25.0591686,34.1288017 25.3716338,33.7326354 25.5363815,33.2626737 C25.7011293,32.792838 25.838506,32.2456971 25.9484503,31.6211283 C25.9484503,31.6211283 27.2902129,24 27.2902129,24 C27.2902129,24 30.6666667,24 30.6666667,24 C30.6666667,24 29.3925644,31.2366128 29.3925644,31.2366128 C29.1021003,32.8865543 28.8212713,34.0529995 28.5500466,34.7365813 C28.2787299,35.420286 27.9001999,35.9952399 27.4144565,36.461944 C26.928713,36.9286481 26.3216105,37.3016357 25.5931795,37.5810331 C24.8647484,37.8603013 23.9526984,38 22.8567226,38 C21.5376055,38 20.5634797,37.8487766 19.9341306,37.5464526 C19.3049041,37.2441287 18.8345339,36.8497172 18.5231733,36.3633411 C18.2116899,35.8769681 18.0405597,35.3675394 18.0095372,34.8351841 C17.9647678,34.0464841 18.0775965,32.8830446 18.3484222,31.3451146 C18.3484222,31.3451146 19.6415492,24 19.6415492,24 Z"
                            id="Fill-6"
                            fill="#B44F26"
                        ></path>
                        <path
                            d="M37.5025745,37.9800214 C37.5025745,37.9800214 35.979505,38 35.979505,38 C34.8650055,38 33.9858948,37.8514841 33.3421729,37.5544553 C32.6984214,37.2572985 32.2120992,36.861769 31.8831765,36.3677356 C31.5541355,35.8737053 31.3726936,35.2646264 31.3386733,34.5401242 C31.3046233,33.8157469 31.433421,32.582321 31.7247998,30.8397217 C31.7247998,30.8397217 32.86876,24 32.86876,24 C32.86876,24 35.7182678,24 35.7182678,24 C35.7182678,24 34.5609541,30.9198952 34.5609541,30.9198952 C34.3678169,32.0750549 34.2755859,32.872735 34.2841724,33.3133162 C34.2928477,33.7540255 34.4728092,34.1444636 34.8243232,34.4850176 C35.1758076,34.8254436 35.7569071,34.9957207 36.5674442,34.9957207 C36.5674442,34.9957207 38,35.0057755 38,35.0057755 C38,35.0057755 37.5025745,37.9800214 37.5025745,37.9800214 Z"
                            id="Fill-7"
                            fill="#B44F26"
                        ></path>
                        <path
                            d="M44.2831546,26.894161 C44.2831546,26.894161 43.7054476,30.2389705 43.7054476,30.2389705 C43.7054476,30.2389705 44.029087,30.2389705 44.029087,30.2389705 C45.0429106,30.2389705 45.7304648,30.1773775 46.0919025,30.0536796 C46.4533095,29.9301097 46.7532792,29.7366714 46.991781,29.4728589 C47.2302827,29.2091745 47.3795184,28.9037475 47.4394878,28.5565781 C47.5144115,28.1226178 47.4400995,27.767048 47.2165518,27.4899998 C46.9930348,27.2130796 46.6782333,27.0378498 46.2724225,26.9642729 C45.9795782,26.9176981 45.3688131,26.894161 44.4401272,26.894161 C44.4401272,26.894161 44.2831546,26.894161 44.2831546,26.894161 Z M39.3333333,38 C39.3333333,38 41.7517148,24 41.7517148,24 C41.7517148,24 45.3422381,24 45.3422381,24 C47.0034935,24 48.0738004,24.0701119 48.5532809,24.2103357 C49.2892144,24.4172689 49.8579917,24.8729963 50.2595517,25.5772683 C50.6611117,26.2817899 50.7655766,27.1913094 50.5730994,28.3061262 C50.4011726,29.3010567 50.1144139,30.0903307 49.7126093,30.6745883 C49.3109269,31.2588428 48.8489999,31.7177481 48.3270116,32.0515538 C47.804901,32.3854875 47.2953593,32.6089591 46.7984169,32.7224742 C46.1211991,32.8560989 45.1645927,32.9227864 43.9284753,32.9227864 C43.9284753,32.9227864 43.2222055,32.9227864 43.2222055,32.9227864 C43.2222055,32.9227864 42.3451405,38 42.3451405,38 C42.3451405,38 39.3333333,38 39.3333333,38 Z"
                            id="Fill-8"
                            fill="#B44F26"
                        ></path>
                        <path
                            d="M60.1551986,37.9859888 C60.1551986,37.9859888 54.8268441,37.9859888 54.8268441,37.9859888 C52.5738241,38.1260662 51.6676681,37.2158219 52.1079952,35.255256 C52.1079952,35.255256 53.6924422,26.433099 53.6924422,26.433099 C54.122162,24.7594101 55.3373568,23.9492087 57.3381852,24.0024634 C57.3381852,24.0024634 62.6666667,24.0024634 62.6666667,24.0024634 C62.6666667,24.0024634 62.1258567,27.0132263 62.1258567,27.0132263 C62.1258567,27.0132263 56.8891881,27.0132263 56.8891881,27.0132263 C56.8891881,27.0132263 56.4274878,29.5839268 56.4274878,29.5839268 C56.4274878,29.5839268 60.6556121,29.5839268 60.6556121,29.5839268 C60.6556121,29.5839268 60.113119,32.6046017 60.113119,32.6046017 C60.113119,32.6046017 55.8850264,32.6046017 55.8850264,32.6046017 C55.8850264,32.6046017 55.4574979,34.985272 55.4574979,34.985272 C55.4574979,34.985272 60.6941983,34.985272 60.6941983,34.985272 C60.6941983,34.985272 60.1551986,37.9859888 60.1551986,37.9859888 Z"
                            id="Fill-9"
                            fill="#B44F26"
                        ></path>
                        <path
                            d="M16.1692428,17.3133547 C16.1692428,17.3133547 14.6461485,17.3333333 14.6461485,17.3333333 C13.5316821,17.3333333 12.6525742,17.184805 12.0088248,16.8877795 C11.365105,16.590754 10.8787843,16.195209 10.5498627,15.701051 C10.2207931,15.2070178 10.0393814,14.5979516 10.0053316,13.8734466 C9.97131135,13.1490665 10.1001086,11.915638 10.3914865,10.1730363 C10.3914865,10.1730363 11.5354134,3.33333333 11.5354134,3.33333333 C11.5354134,3.33333333 14.3849418,3.33333333 14.3849418,3.33333333 C14.3849418,3.33333333 13.2276022,10.2532317 13.2276022,10.2532317 C13.0344655,11.408369 12.9422644,12.2060768 12.9508213,12.646636 C12.9595262,13.0872265 13.1394871,13.477808 13.491,13.8183494 C13.8424833,14.1587659 14.423581,14.3290522 15.2341154,14.3290522 C15.2341154,14.3290522 16.6666667,14.3391039 16.6666667,14.3391039 C16.6666667,14.3391039 16.1692428,17.3133547 16.1692428,17.3133547 Z"
                            id="Fill-10"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M27.166957,11.8694462 C27.166957,11.8694462 26.3998682,7.73649197 26.3998682,7.73649197 C26.3998682,7.73649197 24.1357065,11.8694462 24.1357065,11.8694462 C24.1357065,11.8694462 27.166957,11.8694462 27.166957,11.8694462 Z M31.3333333,17.3333333 C31.3333333,17.3333333 28.2114862,17.3333333 28.2114862,17.3333333 C28.2114862,17.3333333 27.6388065,14.2611388 27.6388065,14.2611388 C27.6388065,14.2611388 22.8149927,14.2611388 22.8149927,14.2611388 C22.8149927,14.2611388 21.1419378,17.3333333 21.1419378,17.3333333 C21.1419378,17.3333333 18,17.3333333 18,17.3333333 C18,17.3333333 24.8674797,4.79440851 24.8674797,4.79440851 C25.2204172,4.2806396 25.5834942,3.90864845 25.9566794,3.67859103 C26.3298645,3.44840883 26.7313023,3.33333333 27.1609298,3.33333333 C27.597338,3.33333333 27.9601325,3.45502201 28.2492505,3.69855534 C28.5383372,3.94221344 28.7706048,4.30746664 28.9460847,4.79440851 C28.9460847,4.79440851 31.3333333,17.3333333 31.3333333,17.3333333 Z"
                            id="Fill-11"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M10.2831851,26.2274924 C10.2831851,26.2274924 9.70544759,29.572309 9.70544759,29.572309 C9.70544759,29.572309 10.029087,29.572309 10.029087,29.572309 C11.0429106,29.572309 11.7304954,29.510713 12.0919025,29.3870152 C12.4533401,29.2634453 12.7532792,29.0700071 12.991781,28.8061949 C13.2302827,28.5425106 13.3795184,28.2370838 13.4394878,27.8899146 C13.5144115,27.4559546 13.4400995,27.1003944 13.2165518,26.8233496 C12.9930348,26.5464296 12.6782333,26.3711811 12.2724225,26.2976355 C11.9795782,26.2510607 11.3688131,26.2274924 10.4401272,26.2274924 C10.4401272,26.2274924 10.2831851,26.2274924 10.2831851,26.2274924 Z M5.33333333,37.3333333 C5.33333333,37.3333333 7.75171484,23.3333333 7.75171484,23.3333333 C7.75171484,23.3333333 11.3422687,23.3333333 11.3422687,23.3333333 C13.0034935,23.3333333 14.0738004,23.4034452 14.5532809,23.5437001 C15.2892144,23.7506332 15.8579917,24.2063291 16.2595517,24.9106005 C16.6611117,25.6151218 16.7655766,26.5246718 16.5730994,27.639466 C16.4011726,28.6343927 16.1144139,29.4236662 15.7126093,30.0079234 C15.3109269,30.5921807 14.8489999,31.0510825 14.3270116,31.3848911 C13.804901,31.7188246 13.2953593,31.9422929 12.7984169,32.0558111 C12.1211991,32.1894357 11.1645927,32.25612 9.92847532,32.25612 C9.92847532,32.25612 9.22220554,32.25612 9.22220554,32.25612 C9.22220554,32.25612 8.34514048,37.3333333 8.34514048,37.3333333 C8.34514048,37.3333333 5.33333333,37.3333333 5.33333333,37.3333333 Z"
                            id="Fill-12"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M19.6415323,23.3333333 C19.6415323,23.3333333 22.8407237,23.3333333 22.8407237,23.3333333 C22.8407237,23.3333333 21.5286008,30.7868251 21.5286008,30.7868251 C21.4012579,31.5099963 21.3391821,32.0488666 21.3423119,32.403814 C21.3687318,32.9624792 21.5765001,33.4127717 21.965924,33.7544363 C22.3552251,34.0962239 22.8025515,34.2671193 23.3079341,34.2671193 C23.7082511,34.2671193 24.1385781,34.1061229 24.5987923,33.7840043 C25.05916,33.4621345 25.3716564,33.0659685 25.5364044,32.5960071 C25.7011524,32.1261716 25.8384986,31.5790312 25.9484431,30.9544628 C25.9484431,30.9544628 27.2902384,23.3333333 27.2902384,23.3333333 C27.2902384,23.3333333 30.6666667,23.3333333 30.6666667,23.3333333 C30.6666667,23.3333333 29.3925932,30.5699506 29.3925932,30.5699506 C29.1020979,32.219888 28.8212684,33.3863354 28.5500741,34.0699137 C28.2787263,34.753621 27.9001956,35.3285746 27.4144515,35.7952783 C26.9287073,36.2619821 26.3216038,36.6349695 25.5931717,36.9143636 C24.8647703,37.1936348 23.9526882,37.3333333 22.8567413,37.3333333 C21.5375915,37.3333333 20.563495,37.1821069 19.9341141,36.8797832 C19.3048867,36.5774594 18.8345465,36.1830482 18.5231854,35.6966755 C18.2117016,35.2102998 18.0405711,34.7008714 18.0095485,34.1685165 C17.9647484,33.3798201 18.0775773,32.2163814 18.3484034,30.6784493 C18.3484034,30.6784493 19.6415323,23.3333333 19.6415323,23.3333333 Z"
                            id="Fill-13"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M36.8359078,37.3133516 C36.8359078,37.3133516 35.3128384,37.3333333 35.3128384,37.3333333 C34.1983388,37.3333333 33.3192281,37.1848172 32.6755062,36.8877879 C32.0317547,36.5906276 31.5454325,36.1950975 31.2165099,35.7010633 C30.8874688,35.2070322 30.7060566,34.5979555 30.6720066,33.873449 C30.6379567,33.1490706 30.7667543,31.9156459 31.0581332,30.1730407 C31.0581332,30.1730407 32.2020933,23.3333333 32.2020933,23.3333333 C32.2020933,23.3333333 35.0516308,23.3333333 35.0516308,23.3333333 C35.0516308,23.3333333 33.8942875,30.2532174 33.8942875,30.2532174 C33.7011502,31.4083758 33.6089192,32.2060572 33.6175057,32.6466391 C33.6261811,33.0873491 33.8061721,33.4777909 34.1576565,33.8183455 C34.5091705,34.158772 35.0902405,34.3290493 35.9008071,34.3290493 C35.9008071,34.3290493 37.3333333,34.339101 37.3333333,34.339101 C37.3333333,34.339101 36.8359078,37.3133516 36.8359078,37.3133516 Z"
                            id="Fill-14"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M43.6165185,26.2274924 C43.6165185,26.2274924 43.0387809,29.572309 43.0387809,29.572309 C43.0387809,29.572309 43.3624204,29.572309 43.3624204,29.572309 C44.376244,29.572309 45.0638287,29.510713 45.4252358,29.3870152 C45.7866734,29.2634453 46.0866126,29.0700071 46.3251143,28.8061949 C46.5636161,28.5425106 46.7128517,28.2370838 46.7728212,27.8899146 C46.8477448,27.4559546 46.7734328,27.1003944 46.5499158,26.8233496 C46.3263681,26.5464296 46.0115666,26.3711811 45.6057558,26.2976355 C45.3129115,26.2510607 44.7021464,26.2274924 43.7734605,26.2274924 C43.7734605,26.2274924 43.6165185,26.2274924 43.6165185,26.2274924 Z M38.6666667,37.3333333 C38.6666667,37.3333333 41.0850482,23.3333333 41.0850482,23.3333333 C41.0850482,23.3333333 44.6756021,23.3333333 44.6756021,23.3333333 C46.3368269,23.3333333 47.4071337,23.4034452 47.8866449,23.5437001 C48.6225783,23.7506332 49.191325,24.2063291 49.592885,24.9106005 C49.994445,25.6151218 50.09891,26.5246718 49.9064327,27.639466 C49.7345059,28.6343927 49.4477778,29.4236662 49.0459426,30.0079234 C48.6442603,30.5921807 48.1823638,31.0510825 47.660345,31.3848911 C47.1382344,31.7188246 46.6286926,31.9422929 46.1317502,32.0558111 C45.4545325,32.1894357 44.497926,32.25612 43.2618087,32.25612 C43.2618087,32.25612 42.5555695,32.25612 42.5555695,32.25612 C42.5555695,32.25612 41.6785044,37.3333333 41.6785044,37.3333333 C41.6785044,37.3333333 38.6666667,37.3333333 38.6666667,37.3333333 Z"
                            id="Fill-15"
                            fill="#FFFFFF"
                        ></path>
                        <path
                            d="M59.4885319,37.3193222 C59.4885319,37.3193222 54.1602091,37.3193222 54.1602091,37.3193222 C51.9071573,37.4593995 51.0010013,36.5491558 51.4413284,34.588588 C51.4413284,34.588588 53.0257754,25.7664307 53.0257754,25.7664307 C53.4554953,24.092743 54.67069,23.2825421 56.6715184,23.3357967 C56.6715184,23.3357967 62,23.3357967 62,23.3357967 C62,23.3357967 61.4592218,26.3465576 61.4592218,26.3465576 C61.4592218,26.3465576 56.2225214,26.3465576 56.2225214,26.3465576 C56.2225214,26.3465576 55.7608528,28.9172626 55.7608528,28.9172626 C55.7608528,28.9172626 59.9889454,28.9172626 59.9889454,28.9172626 C59.9889454,28.9172626 59.4464523,31.9379355 59.4464523,31.9379355 C59.4464523,31.9379355 55.2183597,31.9379355 55.2183597,31.9379355 C55.2183597,31.9379355 54.7908629,34.3186042 54.7908629,34.3186042 C54.7908629,34.3186042 60.0275316,34.3186042 60.0275316,34.3186042 C60.0275316,34.3186042 59.4885319,37.3193222 59.4885319,37.3193222 Z"
                            id="Fill-16"
                            fill="#FFFFFF"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

export default Logo