import Link from 'gatsby-link'
import React from 'react'
import styled, { ThemeProvider } from 'styled-components'

import OrderTotal from './OrderTotal'
import StoreContext from '../context/StoreContext'
import theme from '../utils/style-components-theme'
import { mediaQuery } from '../utils/media-queries'
import getStorePrefix from '../utils/getStorePrefix'

import Logo from './Logo'

import Menu from './Menu/index'
import CheckoutButton from './CheckoutButton'
import { CLOSE_MESSAGE } from '../utils/isClose'
// import SearchForm from './SearchForm'
// import getSearchQueryFromHash from '../utils/getSearchQueryFromHash'

const Header = styled.header`
    justify-content: space-between;
    background: var(--header-bg-color);
    box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    height: 60px;
    padding: 0 var(--spacing-global);
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 2;

    a.logo {
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        display: flex;
    }

    ${mediaQuery.isBig`
        justify-content: space-between;
        padding: 0 var(--spacing-global);
        height: 100px;

        .logo svg {
            width: 100px;
            height: 60px;
        }
    `}
`

const CheckoutArea = styled.div`
    align-items: center;
    display: flex;
    color: var(--header-color);

    .order-total {
        font-size: 0.8rem;
        margin-right: 0.5rem;
    }
`

const Close = styled.div`
    background: ${props => props.theme.brandColor1Hightlight};
    padding: 0.5rem;
    text-align: center;
`

export default ({ siteTitle, isStore }) => {
    // const query = location ? getSearchQueryFromHash(location?.hash) : ''
    return (
        <StoreContext.Consumer>
            {({ order, store, isClose }) => {
                const prefix = getStorePrefix(store)
                return (
                    <ThemeProvider theme={theme}>
                        <Header>
                            <Menu store={store} />

                            <Link
                                className="logo"
                                to={`${prefix}/`}
                                title={siteTitle}
                            >
                                <Logo
                                    style={{
                                        display: 'block',
                                    }}
                                />
                            </Link>

                            {isStore && (
                                <CheckoutArea>
                                    {order.length ? (
                                        <OrderTotal
                                            className="order-total"
                                            order={order}
                                        />
                                    ) : null}
                                    <CheckoutButton />
                                </CheckoutArea>
                            )}
                        </Header>
                        {isClose && <Close>{CLOSE_MESSAGE}</Close>}

                        {/* {isStore && <SearchForm value={query} />} */}
                    </ThemeProvider>
                )
            }}
        </StoreContext.Consumer>
    )
}
