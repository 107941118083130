import React, { useState } from 'react'
import styled, { ThemeProvider } from 'styled-components'
// import { Link } from 'react-scroll'

import MenuCategories from './MenuCategories'
import MenuCorporate from './MenuCorporate'
import theme from '../../utils/style-components-theme'

import IcnMenu from '../../assets/icn-menu.svg'

const Wrapper = styled.div`
    overflow: auto;
    -webkit-overflow-scrolling: touch;
`

const Nav = styled.nav`
    background-color: #fff;
    border-radius: 0.25rem;
    border: solid 2px ${props => props.theme.gray};
    display: flex;
    flex-direction: column;
    max-height: 80vh;
    overflow: hidden;
    position: absolute;
    z-index: 1;
`

const NavWrapper = styled.div`
    ul {
        font-size: 0.85rem;
        list-style: none;
        margin: 0;
    }

    li {
        margin: 0;
    }
`

const Button = styled.button`
    background: none;
    display: flex;
    align-items: center;
    border: 0;
    cursor: pointer;
    display: flex;
    padding: 0.5rem 0;

    span {
        margin-left: 0.5rem;
        text-transform: uppercase;
        color: var(--header-color);
    }

    #Menu-Icon-Artboard {
        fill: var(--header-color);
    }
`

const Menu = ({ store }) => {
    const [open, setOpen] = useState(false)

    return (
        <ThemeProvider theme={theme}>
            <NavWrapper
                onScroll={e => {
                    e.preventDefault()
                    e.stopPropagation()
                }}
            >
                <Button
                    onClick={() => {
                        setOpen(!open)
                    }}
                >
                    <IcnMenu /> <span>Menú</span>
                </Button>
                {open && (
                    <Nav>
                        <Wrapper>
                            {store && <MenuCategories store={store} />}
                        </Wrapper>
                        <MenuCorporate />
                    </Nav>
                )}
            </NavWrapper>
        </ThemeProvider>
    )
}

export default Menu
