import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

const properties = css`
    background: ${props => (props.outline ? 'none' : props.theme.brandColor1)};
    border-radius: 0.5rem;
    border: ${props => props.outline ? `solid 1px ${props.theme.gray}` : '0'};
    color: ${props => props.theme.brandColor1Dark};
    cursor: pointer;
    font-size: ${props => (props.slim ? '0.75rem' : '1rem')};
    font-weight: bold;
    padding: ${props => (props.slim ? '0.25rem 0.5rem' : '0.5rem 1rem')};
    transition: background 100ms;
    line-height: 1rem;

    &:hover {
        background: ${props => props.theme.brandColor1Hightlight};
    }

    &:disabled {
        background: none;
        box-shadow: inset 0 0 0 2px ${props => props.theme.gray};
        color: ${props => props.theme.gray};
        pointer-events: none;
    }
`

const Button = styled.button`
    ${properties}
`

export const ButtonLink = styled(Link)`
    ${properties}
    text-decoration: none;
`

export default Button
