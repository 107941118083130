import React, { useState } from 'react'
import styled from 'styled-components'

import Field from './styled/Field'
import Button, { ButtonLink } from './styled/Button'
import FormButtonWrapper from './styled/FormButtonWrapper'

import StoreContext from '../context/StoreContext'

import submitForm from '../utils/submitForm'
import { getProductList } from '../utils/getProductList'
import getTotal from '../utils/getTotal'

import fbTracking from '../utils/fb-tracking'
import useOrdersActions from '../hooks/useOrdersActions'

const getLabelClass = valid => (valid ? 'required invalid' : 'required')

const ThankYou = styled.div`
    margin-top: 3rem;
    text-align: center;

    h3 {
        margin: 0 0 1rem;
        color: ${props => props.theme.brandColor2};
    }
`

const Radio = styled.div`
    display: flex;
    align-items: center;

    input {
        margin-right: 0.25rem;
    }
`

const handleFocus = e => {
    e.target.parentNode.classList.add('focus')
}

const handleBlur = e => {
    e.target.parentNode.classList.remove('focus')
}

const OrderForm = ({ onSubmit, store }) => {
    const { add } = useOrdersActions({ store })

    return (
        <Form
            onSubmit={onSubmit}
            store={store}
            onCreateOrder={async order => {
                await add(order)
            }}
        />
    )
}

const Form = ({ onSubmit, store, onCreateOrder }) => {
    const isPulpe = store === 'pulpe'
    const isEmerson = store === 'emerson'

    const initialValue = {
        name: '',
        email: '',
        phone: '',
    }

    const initialValidity = {
        name: false,
        email: false,
        phone: false,
    }

    if (isEmerson) {
        initialValue.badge = ''
        initialValidity.badge = false

        initialValue.payment = 'card'
        initialValidity.payment = false
    }

    const [formValue, setFormValue] = useState(initialValue)
    const [validity, setValidity] = useState(initialValidity)

    const [thanks, setThanks] = useState(false)
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const [userEmail, setUserEmail] = useState('')

    const onChangeHandler = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.value,
        })
    }

    const onRadioChangeHandler = e => {
        setFormValue({
            ...formValue,
            [e.target.name]: e.target.id,
        })
    }

    return (
        <StoreContext.Consumer>
            {({ order, clearOrder }) => {
                return thanks ? (
                    <ThankYou>
                        <h3>Su ordén ha sido enviada</h3>
                        {isPulpe ? (
                            <p>En breve le contactaremos</p>
                        ) : (
                            <>
                                <p>
                                    Gracias por tu pedido. Puedes darle
                                    seguimiento:
                                </p>
                                <p>
                                    <ButtonLink
                                        to={`/emerson/mispedidos/#/${userEmail}`}
                                    >
                                        Ver estatus del pedido
                                    </ButtonLink>
                                </p>
                            </>
                        )}
                        <Button
                            outline="true"
                            onClick={() => {
                                onSubmit()
                                clearOrder()
                            }}
                        >
                            Cerrar
                        </Button>
                    </ThankYou>
                ) : (
                    <form
                        onSubmit={async e => {
                            e.preventDefault()
                            setLoading(true)

                            try {
                                if (isPulpe) {
                                    await submitForm('sujyXCCLXo75wGzkt4MNl', {
                                        ...formValue,
                                        products: getProductList(order),
                                    })
                                    fbTracking('track', 'Purchase', {
                                        value: getTotal(order),
                                        currency: 'CRC',
                                        content_type: 'product',
                                        content_ids: order.map(
                                            ({ REF }) => REF
                                        ),
                                    })
                                } else {
                                    onCreateOrder({
                                        ...formValue,
                                        items: JSON.stringify(order),
                                        total: getTotal(order),
                                    })

                                    setUserEmail(formValue.email)
                                }

                                setLoading(false)
                                setFormValue(initialValue)
                                setThanks(true)
                            } catch (error) {
                                console.log(error)
                                setLoading(false)
                                setError(true)
                            }
                        }}
                        onInput={e => {
                            if (e.target.checkValidity) {
                                setValidity({
                                    ...validity,
                                    [e.target.id]: !e.target.checkValidity(),
                                    form: e.currentTarget.checkValidity(),
                                })
                            }
                        }}
                    >
                        <Field
                            htmlFor="name"
                            className={getLabelClass(validity.name)}
                        >
                            <span>Nombre Completo</span>
                            <input
                                autoComplete="name"
                                required
                                type="text"
                                id="name"
                                name="name"
                                value={formValue.name}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={onChangeHandler}
                            />
                        </Field>

                        <Field
                            htmlFor="email"
                            className={getLabelClass(validity.email)}
                        >
                            <span>Email</span>
                            <input
                                autoComplete="email"
                                id="email"
                                name="email"
                                onBlur={handleBlur}
                                onChange={onChangeHandler}
                                onFocus={handleFocus}
                                required
                                type="email"
                                value={formValue.email}
                            />
                        </Field>

                        <Field
                            htmlFor="phone"
                            className={getLabelClass(validity.phone)}
                        >
                            <span>{isEmerson ? 'Extensión' : 'Teléfono'}</span>
                            <input
                                autoComplete="phone"
                                id="phone"
                                name="phone"
                                onBlur={handleBlur}
                                onChange={onChangeHandler}
                                onFocus={handleFocus}
                                required
                                type="tel"
                                value={formValue.phone}
                            />
                        </Field>

                        {isEmerson && (
                            <>
                                <Field
                                    htmlFor="asociation"
                                    className={getLabelClass(
                                        validity.asociation
                                    )}
                                >
                                    <span>Método de pago</span>
                                    <Radio>
                                        <input
                                            defaultChecked={true}
                                            id="card"
                                            name="payment"
                                            onChange={onRadioChangeHandler}
                                            required
                                            type="radio"
                                            value={formValue.payment === 'card'}
                                        />
                                        <label htmlFor="card">Tarjeta</label>
                                    </Radio>
                                    <Radio>
                                        <input
                                            id="asociation"
                                            name="payment"
                                            onChange={onRadioChangeHandler}
                                            required
                                            type="radio"
                                            value={
                                                formValue.payment ===
                                                'asociation'
                                            }
                                        />
                                        <label htmlFor="asociation">
                                            Asociación
                                        </label>
                                    </Radio>
                                    <Radio>
                                        <input
                                            id="cash"
                                            name="payment"
                                            onChange={onRadioChangeHandler}
                                            required
                                            type="radio"
                                            value={formValue.payment === 'cash'}
                                        />
                                        <label htmlFor="cash">Efectivo</label>
                                    </Radio>
                                </Field>
                                {formValue.payment === 'asociation' && (
                                    <Field
                                        htmlFor="badge"
                                        className={getLabelClass(
                                            validity.badge
                                        )}
                                    >
                                        <span>Número de id</span>
                                        <input
                                            autoComplete="on"
                                            id="badge"
                                            name="badge"
                                            onBlur={handleBlur}
                                            onChange={onChangeHandler}
                                            onFocus={handleFocus}
                                            required
                                            type="number"
                                            value={formValue.badge}
                                        />
                                    </Field>
                                )}
                            </>
                        )}
                        <FormButtonWrapper>
                            <Button type="submit" disabled={!validity.form}>
                                Enviar
                            </Button>
                            <p className={loading ? 'show' : ''}>Enviando...</p>
                            <p className={error ? 'error show' : ''}>
                                Disculpe, ocurrió un error puedes llamarnos al
                                72155831.
                            </p>
                        </FormButtonWrapper>
                    </form>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default OrderForm
