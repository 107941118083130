const detector = (function() {
    let userAgent = process.browser ? navigator.userAgent.toLowerCase() : 'phone'
    const detection = function(agent) {
        void 0 !== agent && (userAgent = agent.toLowerCase())
        return /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            userAgent
        )
            ? 'tablet'
            : /(mobi|ipod|phone|blackberry|opera mini|fennec|minimo|symbian|psp|nintendo ds|archos|skyfire|puffin|blazer|bolt|gobrowser|iris|maemo|semc|teashark|uzard)/.test(
                  userAgent
              )
            ? 'phone'
            : 'desktop'
    }
    return {
        type: detection(),
        isMobile: detection() !== 'desktop',
        isPhone: detection() === 'phone',
    }
})()

export default detector
