import React from 'react'

import styled from 'styled-components'

import IconClose from '../assets/icn-close.svg'

const WarningWrapper = styled.div`
    background-color: ${props => props.theme.grayLight};
    border-radius: 0.25rem;
    border: solid 1px ${props => props.theme.brandColor1};
    font-size: 0.8rem;
    line-height: ${props => (props.mode === 'tight' ? '1.1' : '1.5')};
    padding: ${props => props.mode === 'tight' ? '0.2rem 0.5rem' : '0.5rem 1rem'};
    position: relative;
    text-align: ${props => props.align || 'left'};
    max-width: 80vw;
    margin: auto;

    ul {
        margin: 0;
        padding: 0;
    }

    li {
        margin: 0 0 0 0.5rem;
        padding: 0;
    }

    button {
        background: ${props => props.theme.brandColor2};
        border-radius: 50px;
        border: 0;
        cursor: pointer;
        padding: 0.3rem;
        position: absolute;
        right: -0.5rem;
        top: -0.5rem;


        svg {
            display: block;
        }
    }
`

const Warning = ({ children, onClose }) => {
    return (
        <WarningWrapper>
            {children}
            {onClose ? (
                <button onClick={onClose} aria-label="cerrar">
                    <IconClose />
                </button>
            ) : null}
        </WarningWrapper>
    )
}

export default Warning
