import React from 'react'
import styled from 'styled-components'
import Price from './Price'
import IcnTrash from '../assets/icn-trash.svg'

import StoreContext from '../context/StoreContext'

const Ul = styled.ul`
    margin: 0;
    padding: 0;
    overflow: auto;
`

const Li = styled.li`
    display: flex;
    border-bottom: solid 2px ${props => props.theme.gray};
    margin: 0;
    padding: 0.5rem 0;
`

const Quantity = styled.b`
    white-space: nowrap;
    margin-right: 0.5rem;
`
const Product = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 1rem;
    flex: 1;
`

const ButtonDelete = styled.button`
    align-items: center;
    background: none;
    border: 0;
    cursor: pointer;
    display: flex;
    margin-right: 0.5rem;
    padding: 0;
`

const RemoveItemButton = ({ onClick }) => {
    return (
        <ButtonDelete onClick={onClick}>
            <IcnTrash />
        </ButtonDelete>
    )
}

const CheckoutList = () => {
    return (
        <StoreContext.Consumer>
            {({ order, removeItem }) => {
                return (
                    <Ul>
                        {order.map((item, i) => {
                            const {
                                Nombre,
                                price,
                                quantity,
                            } = item
                            return (
                                <Li key={i}>
                                    <RemoveItemButton
                                        onClick={() => {
                                            removeItem(item)
                                        }}
                                    />
                                    <Quantity>{quantity} x</Quantity>
                                    <Product>{Nombre}</Product>
                                    <Price>{price * quantity}</Price>
                                </Li>
                            )
                        })}
                    </Ul>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default CheckoutList
