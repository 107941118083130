import styled from 'styled-components'


const Whatsapp = styled.a`
    bottom: 1rem;
    height: 64px;
    position: fixed;
    right: 1rem;
    width: 64px;
    z-index: 3;

    svg {
        height: 64px;
        width: 64px;
        animation: float 4s ease-in-out infinite;
        filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.4));
        /* transform: scale(0.8); */
    }

    @keyframes float {
        0% {
            transform: translatey(0px) scale(0.8);
        }
        50% {
            transform: translatey(-10px) scale(1);
        }
        100% {
            transform: translatey(0px) scale(0.8);
        }
    }
`

export default Whatsapp
