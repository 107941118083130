import React from 'react'

import styled from 'styled-components'

const H4 = styled.h4`
    padding: 0.5rem;
    background-color: ${props => props.theme.grayLight};
`

const MenuSectionItem = ({ children }) => <H4>{children}</H4>

export default MenuSectionItem;
