import React from 'react'
import styled from 'styled-components'
import MenuItem from './MenuItem'
import MenuSectionItem from './MenuSectionItem'
import StoreContext from '../../context/StoreContext'
import getStorePrefix from '../../utils/getStorePrefix'

// const items = [
//     {
//         to: 'servicios',
//         text: 'Servicios',
//     },
//     {
//         to: 'nosotros',
//         text: 'Nosotros',
//     },
//     {
//         to: 'clientes',
//         text: 'Clientes',
//     },
//     {
//         to: 'contacto',
//         text: 'Contacto',
//     },
// ]

const Navigation = styled.ul``

const items = [
    {
        to: '/',
        text: 'Tienda',
    },
    {
        to: '/que-hacemos',
        text: '¿Que hacemos?',
    },
    {
        to: '/contacto',
        text: 'Contacto',
    },
]

const MenuCorporate = () => {
    return (
        <StoreContext.Consumer>
            {({ store }) => {
                store = store || 'pulpe'
                const prefix = getStorePrefix(store)
                return (
                    <Navigation>
                        <li>
                            <MenuSectionItem>Empresa</MenuSectionItem>
                        </li>
                        {items.map(({ to, text }, i) => (
                            <li key={i}>
                                <MenuItem to={to === '/' ? `${prefix}/` : to}>
                                    {text}
                                </MenuItem>
                            </li>
                        ))}
                    </Navigation>
                )
            }}
        </StoreContext.Consumer>
    )
}

export default MenuCorporate
