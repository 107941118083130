import React from 'react'
import { Link } from 'gatsby'
import { trackCustomEvent } from 'gatsby-plugin-google-analytics'
import styled from 'styled-components'

const MenuLink = styled(Link)`
    color: ${props => props.theme.black};
    cursor: pointer;
    text-decoration: none;
    padding: 0.5rem;
    display: block;
    box-sizing: border-box;
    white-space: nowrap;

    &.active {
        background-color: ${props => props.theme.brandColor1Hightlight};
    }
`

const MenuItem = ({ children, to }) => {
    return (
        <MenuLink
            onClick={() => {
                trackCustomEvent({
                    // string - required - The object that was interacted with (e.g.video)
                    category: 'Menu',
                    // string - required - Type of interaction (e.g. 'play')
                    action: 'Click',
                    // string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
                    label: children,
                })
            }}
            activeClassName="active"
            to={to}
            // spy={true}
            // hashSpy={true}
            // smooth={true}
            // offset={to === 'clientes' ? -220 : -130}
            // duration={750}
        >
            {children}
        </MenuLink>
    )
}

export default MenuItem
