const HOUR = 15
const MIN = 0

export const CLOSE_MESSAGE =
    'Cerramos a la 3:00pm, esperamos poder ampliar nuestro horario en el futuro cercano'

const isClose = async store => {
    if (store === 'emerson') {
        const date = await fetch(
            '//worldtimeapi.org/api/timezone/America/Costa_Rica'
        )
            .then(res => res.json())
            .then(({ datetime }) => new Date(datetime))
        if (date.getHours() > HOUR) {
            return true
        }

        return date.getHours() === HOUR && date.getMinutes() >= MIN
    }

    return false
}

export default isClose
