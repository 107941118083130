import styled from 'styled-components'

const FormButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    p {
        margin: 0 0 0 1rem;
        font-size: 0.85rem;
        opacity: 0;
        transition: opacity 300ms ease;
        position: absolute;
        pointer-events: none;

        &.error {
            color: ${props => props.theme.errorColor};
        }

        &.show {
            opacity: 1;
            position: inherit;
        }
    }
`

export default FormButtonWrapper
