import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import device from '../utils/device-detection'

import CheckoutEmpty from './CheckoutEmpty'
import CheckoutList from './CheckoutList'
import CheckoutOrderButton from './CheckoutOrderButton'
import CheckoutTotal from './CheckoutTotal'
import Dialog from './Dialog'
import OrderForm from './OrderForm'
import Warning from './Warning'

import Button from './styled/Button'
import DialogHeader from './styled/DialogHeader'

const Content = styled.div`
    flex: 1;
    overflow-y: scroll;
`

const Form = styled.div`
    height: 100%;
`

const Order = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    /* height: calc(100vh - 6rem); */
`

const OrderGrid = styled.div`
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: 100% 100%;
    height: 100%;
    overflow: hidden;
    padding: 1px;
`

const OrderGridSlide = styled.div`
    transform: translateX(0);
    transition: transform 200ms ease;
    height: 100%;
    overflow: scroll;

    &.hide,
    &.show {
        transform: translateX(calc(-100% - 1rem));
    }

    form {
        margin: 0;
    }
`
const Small = styled.small``

const CheckoutOrder = ({ order, setShowForm, onClose, store }) => {
    useEffect(() => {
        return () => {}
    })

    return (
        <Order>
            <DialogHeader>
                <h3>Su Ordén</h3>
                {order.length ? (
                    <Button
                        style={{
                            padding: '0.25rem 0.75rem',
                            borderRadius: '2rem',
                            fontSize: '0.75rem',
                        }}
                        onClick={() => {
                            onClose()
                        }}
                    >
                        Comprar más
                    </Button>
                ) : null}
            </DialogHeader>
            <Content>
                {order.length ? (
                    <CheckoutList />
                ) : (
                    <CheckoutEmpty
                        onClick={() => {
                            onClose()
                        }}
                    />
                )}
            </Content>
            <div>
                <p>
                    <CheckoutTotal order={order} />
                </p>
                {store === 'pulpe' && (
                    <Warning>
                        <Small>
                            <ul>
                                <li>
                                    El total puede variar si usted solicitó
                                    productos no listados
                                </li>
                                <li>Imagenes con caracter ilustrativo</li>
                                <li>
                                    Entregas se coordinan por Whatsapp: Escazú,
                                    Guachipelin y Santa Ana, otras areas por
                                    Glovo
                                </li>
                            </ul>
                        </Small>
                    </Warning>
                )}
                <CheckoutOrderButton
                    store={store}
                    order={order}
                    setShowForm={setShowForm}
                />
            </div>
        </Order>
    )
}

const OrderCheckout = ({ order, onClose, store }) => {
    const [showForm, setShowForm] = useState(false)

    const FinalCheckoutOrder = () => {
        return (
            <CheckoutOrder
                store={store}
                order={order}
                setShowForm={setShowForm}
                onClose={onClose}
            />
        )
    }

    return store === 'pulpe' && device.isPhone ? (
        <FinalCheckoutOrder />
    ) : (
        <OrderGrid>
            <OrderGridSlide className={showForm ? 'hide' : ''}>
                <FinalCheckoutOrder />
            </OrderGridSlide>
            <OrderGridSlide className={showForm ? 'show' : ''}>
                <Form>
                    <DialogHeader>
                        <h3>Información</h3>
                        {order.length ? (
                            <button
                                onClick={() => {
                                    setShowForm(false)
                                }}
                            >
                                Volver
                            </button>
                        ) : null}
                    </DialogHeader>
                    <OrderForm onSubmit={onClose} store={store} />
                </Form>
            </OrderGridSlide>
        </OrderGrid>
    )
}

const Checkout = ({ order, onClose, store }) => {
    return (
        <Dialog
            onDialogClick={() => {
                onClose()
            }}
        >
            <OrderCheckout order={order} onClose={onClose} store={store} />
        </Dialog>
    )
}

export default Checkout
