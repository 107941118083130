import React from 'react'

import styled from 'styled-components'
import device from '../utils/device-detection'
import { getListForWhatsapp } from '../utils/getProductList'

const ButtonWrapper = styled.div`
    margin-top: 1rem;

    a,
    button {
        align-items: center;
        background-color: ${props => props.theme.brandColor2};
        border-radius: 0.2rem;
        border: 0;
        color: #fff;
        cursor: pointer;
        display: block;
        padding: 0.25rem 1rem 0.25rem 0.75rem;
        position: relative;
        text-align: center;
        text-decoration: none;
        width: 100%;

        &.disabled {
            cursor: not-allowed;
            opacity: 0.3;
        }
    }
`

const CheckoutOrderButton = ({ order, setShowForm, store }) => {
    const className = order.length === 0 ? 'disabled' : ''
    const label = 'Hacer Pedido'
    const warningText = 'Agrega productos para hacer tu pedido'

    return store === 'pulpe' && device.isPhone ? (
        <ButtonWrapper>
            <a
                onClick={e => {
                    if (order.length === 0) {
                        e.preventDefault()
                        alert(warningText)
                    }
                }}
                className={className}
                href={`https://api.whatsapp.com/send?phone=50672155831&text=${getListForWhatsapp(
                    order
                )}`}
            >
                {label}
            </a>
        </ButtonWrapper>
    ) : (
        <ButtonWrapper>
            <button
                onClick={e => {
                    if (order.length === 0) {
                        e.preventDefault()
                        alert(warningText)
                    } else {
                        setShowForm(true)
                    }
                }}
                className={className}
            >
                {label}
            </button>
        </ButtonWrapper>
    )
}

export default CheckoutOrderButton
